import { useAuthStore } from "~~/stores/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthStore();
  const token = auth.token;
  const isAdminRoute = to.meta.key === 'admin';
  const urlRedirect = isAdminRoute ? '/admin/login' : '/user/login';
  if ((token !== undefined) && (token !== null) && (token !== '')) {
    const authenticated = isAdminRoute ? await auth.checkTokenAdminUser() : await auth.checkToken();
    if (!authenticated) {
      auth.setRedirectUrl(to.fullPath);  // Salva a URL original
      return navigateTo(urlRedirect);
    }
  } else {
    auth.setRedirectUrl(to.fullPath);  // Salva a URL original
    return navigateTo(urlRedirect);
  }
});
